@import 'antd/dist/antd.css';

:root {
    --app-container-radius: 10px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    border: none;
    outline: none;
    user-select: none;
    -webkit-user-select: none;
    -webkit-user-drag: none;
}

html {
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
}

a {
    text-decoration: none;
    color: initial;
}

li {
    list-style: none;
}

.hidden {
    display: none;
}

.section-wrapper {
    width: 80%;
	height: 100%;
    padding: 0 20px;
	overflow-x: hidden;
	overflow-y: scroll;
}

.section-title {
    text-align: center;
    padding: 40px 20px;
    font-size: 1.5rem;
    font-weight: 700;
    cursor: default;
}

@font-face {
  font-family: 'Kalam';
  src: url('./fonts/Kalam-Regular.ttf');
}


@font-face {
  font-family: 'Nanum';
  src: url('./fonts/NanumMyeongjo-Regular.ttf');
}